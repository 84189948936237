@tailwind base;
@tailwind components;
@tailwind utilities;


* { 
    margin: 0;
    padding: 0;
    border-radius: 0;
    outline: 0;
    vertical-align: baseline;
    font-weight: inherit;
	font-family: inherit;
	font-style: inherit;
	font-size: 100%;
    box-sizing: border-box;
}
html{
    font-size: 16px;
}
body{
    background-color: #000000;
}
.landingpage{
    background-image:   linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,1)), url(./components/images/landing2.jpg);
    background-position: center;
    background-size: cover;
}


.header {
    position: fixed;
    height: 90px;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    transition: .3s ease-in;
    overflow: hidden;
    background-color: rgba(0,0,0,.9);
}

.header .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1240px;
    margin: auto;
    height: 100%;
    padding: 0 1rem;
}

.header .nav-menu a {
    color: rgb(226 232 240);
    cursor:pointer;
}

.header .navbar img {
    width: 250px;
    height: auto;
}

.header .nav-menu {
    display: flex;
}

.header .nav-item {
    padding: 1rem;
    font-weight: 500;
}

.nav-item .active{
        padding-bottom: 12px;
        border-bottom: 3px solid rgb(132 204 22);
}

.header .nav-item a:hover {
    padding-bottom: 12px;
    border-bottom: 3px solid rgb(132 204 22);
}

.hamburger {
    display: none;
}

.slick-dots li button:before {
    font-family: 'slick';
    font-size: 6px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: '•';
    text-align: center;
    opacity: .25;
    color: white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
    opacity: .75;
    color: rgb(132 204 22);
}

div.slick-list{
    padding-inline: 0px;
}

.slick-dots {
    position: initial;
    bottom: -25px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
}


@media screen and (max-width:940px) {
    .header {
        max-width: 100%;
        background-color: rgba(0,0,0,.9);
    }

    .header .navbar {
        max-width: 100%;
    }

    .hamburger {
        display: block;
    }

    .nav-menu {
        position: fixed;
        left: -100%;
        top: 90px;
        flex-direction: column;
        background-color: rgba(0,0,0,.9);
        width: 100%;
        height: 90vh;
        z-index: 999;
        text-align: center;
        transition: .3s;
    }

    .nav-menu.active {
        left: 0;
    }

    .nav-item {
        margin: 1.5rem 0;
    }

    .header .navbar img {
        width: 150px;
    }
}

@media screen and (max-width:450px) {
    .landingpage{
        height: 800px;
    }

}










